import * as firebase from "firebase/app";
import "firebase/auth";


const app = firebase.initializeApp({
    apiKey: "AIzaSyDlBiW7MQqw3qxnd5fnpyoViw-cvwD2Ec0",
    authDomain: "library-d637e.firebaseapp.com",
    projectId: "library-d637e",
    storageBucket: "library-d637e.appspot.com",
    messagingSenderId: "986038958016",
    appId: "1:986038958016:web:e4bbc53f764683e0ce3a25"
  });
export default app;